import axios from 'axios'

// export const API_URL = 'https://www.myrxlaser.com'
// export const API_URL = 'https://flat-sun-42336.botics.co'
// export const API_URL = 'https://multi-radiance-medi-42-staging.botics.co'
export const API_URL = 'https://dev.myrxlaser.com'
// export const API_URL = 'https://toad-feasible-vigorously.ngrok-free.app'
// export const API_URL = 'http://localhost:8000'

export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51Nv1gDEwnoXpz0BlLmOWqqV5tpGFSEJbh7Z9hDfZkWbz1W3jFtAyn6Emd0ITbjXe87p7QxOwxUOTLRgn0BbMTIcr00mVPApDce'
// export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51PtEbEGCFjkYDSx9NIStRVSKQX0N7C0Z1NkGKKz3HKzb8OTJ457XAACDdTj0skBQwvB2Y3vMccvH3IdMiNq7h17N00eiVMJtp9'


export const apiErrorCode = {
  unauthorized: 401,
  accessDenied: 430,
  sessionExpired: 423,
  validationError: 400,
  emailNotVerified: 403,
  internalError: 500
}

export const apiSuccessCode = {
  success: 200
}

export const $axios = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    authorization: `Token ${localStorage.getItem('accessToken')}`
  }
})

$axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken')
      ? localStorage.getItem('accessToken')
      : sessionStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Token ${token}`
    } else {
      config.headers.Authorization = 'Basic c2xpY2U6c2xpY2VAMTIz'
    }
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

export const $axiosForm = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'multipart/form-data',
    authorization: `Token ${localStorage.getItem('accessToken')}`,
  }
})

$axiosForm.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken')
      ? localStorage.getItem('accessToken')
      : sessionStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Token ${token}`
    } else {
      config.headers.Authorization = 'Basic c2xpY2U6c2xpY2VAMTIz'
    }
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  }
)
